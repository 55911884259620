<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"

    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @editSaturation="editSaturation"

    />
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addDialogData"
        @addDialogSub="addDialogSub"
        @start_timeChange="start_timeChange"
        @end_timeChange="end_timeChange"

    />

  </div>
</template>
<script>
import {timer} from '@/assets/js/comment'
import {editSaturation, getSaturation} from '@/api/project_management/working_saturation'

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '工作饱和度情况表',//标题名称
        butOperate: []
      },
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '饱和度状态',
          field: 'saturation',
          type: 3,
        }, {
          name: '姓名',
          field: 'userName',
          type: 1,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值
        saturationOptions: [
          {value: '1',label: '空闲'},
          {value: '2',label: '有余'},
          {value: '3',label: '饱和'}
        ],



        //高级搜索的字段
        heighKewWordField: {saturation: '', userName: '',},
        //tabs 标题
        tabsData: [{
          label: '全部',
          name: '0',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '工号',
          field: 'job_num',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '姓名',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '岗位',
          field: 'station_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '执行项目数',
          field: 'project_task_count',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '饱和度状态',
          field: 'saturation_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '修改',
            type: 'primary',
            method: 'editSaturation',
            status: '0',
            id: 20000,
          }],
        }],
      },
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '修改工作饱和度',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '工作饱和度',
          field: 'saturation',
          type: 3,
          show: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          user_id: '',
          saturation: '',
        },
        // 下拉框的 options和单选框，多选框
        saturationProp: [
          //  饱和度：1=空闲，2=有余，3=饱和
          {value: 1, label: '空闲'},
          {value: 2, label: '有余'},
          {value: 3, label: '饱和'},
        ],
      },
      //表格数据
      tableData: [],

    };
  },
  created() {
    this.initLoad();
    if (localStorage.getItem('departmentName') == '总经办' || localStorage.getItem('stationName') == '技术经理'){
      this.tableProp.table[6].tableOperate[0].status = '20000'
    }
  },
  mounted() {
  },
  methods: {
    start_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    end_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
      }
      if(this.tabsProp.heighKewWordField.userName!=""){
        params.userName = this.tabsProp.heighKewWordField.userName
      }
      if(this.tabsProp.heighKewWordField.saturation!=""){
        params.saturation = this.tabsProp.heighKewWordField.saturation
      }

      getSaturation(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        for (let i=0; i<res.data.length; i++){
          //饱和度：1=空闲，2=有余，3=饱和
          switch (res.data[i].saturation) {
            case 1:
              res.data[i].saturation_name = '空闲';
              break;
            case 2:
              res.data[i].saturation_name = '有余';
              break;
            case 3:
              res.data[i].saturation_name = '饱和';
              break;
          }
          if(res.data[i].user_station){
            res.data[i].department_name = res.data[i].user_station.department_name
            res.data[i].station_name = res.data[i].user_station.station_name
          }
        }
        console.log(res.data)
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 修改 按钮点击
    editSaturation(row, index) {
      console.log(row)
      console.log(index)
      console.log('修改 点击')
      this.addDialogData.addDialogShowData.user_id = row.user_id + ''
      this.addDialogData.addDialogShowData.saturation = row.saturation
      this.addDialogData.isDialog = true
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      let subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      subData.saturation = subData.saturation+''
      console.log(subData)
      editSaturation(subData).then((result) => {
        this.addDialogData.isDialog = false;
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.initLoad()
      })
    },


  },
};
</script>
<style lang="scss" scoped></style>
