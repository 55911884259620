import http from '../http';

//获取列表数据
export function getSaturation (params) {
    return http({
        url: "/api/project/saturationPage",
        method: "get",
        params:params
    })
}

//修改饱和度
export function editSaturation (data) {
    return http({
        url: "/api/project/saturation",
        method: "post",
        data:data
    })
}

